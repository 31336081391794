<template>
  <b-modal
    id="modal-impersonate"
    ref="modal"
    v-model="open"
    size="md"
    no-close-on-backdrop
    hide-footer
    centered
    title="IMPERSONATE"
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createCityForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(createRole)"
        >
          <b-row>
            <b-col cols="12">
              <h1 class="text-center mt-2 mb-0">
                {{ user.last_name ? `${user.first_name} ${user.last_name}` : user.first_name }}
              </h1>
              <h5 class="text-center my-2 mb-1">
                {{ user.email }}
              </h5>
              <h3
                v-if="generatedPassword"
                class="text-center mt-0 mb-2 font-weight-bold"
              >
                {{ generatedPassword }}
              </h3>
            </b-col>

            <!-- submit and reset -->
            <b-col
              cols="12"
            >
              <b-button
                type="submit"
                variant="primary"
                class="w-100 mb-1"
                :href="`https://${domain}/login?email=${user.email}&password=${generatedPassword}`"
                target="_blank"
              >
                Login
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BForm,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { appEnv } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const TenantUserRepository = RepositoryFactory.get('tenantUser')

function initialState() {
  return {
    appEnv,
    open: false,
    generatedPassword: null,
    tenant: null,
    userType: null,
    loading: false,
    user: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BForm,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    domain() {
      const tenantName = this.tenant.name.replaceAll('_tenant', '')
      if (this.appEnv === 'local') return 'localhost:8080'
      if (this.appEnv === 'staging') {
        if (this.userType === 'merchant') return `${tenantName}.merchant.curfox.parallaxtec.com`
        return `${tenantName}.office.curfox.parallaxtec.com`
      }
      if (this.userType === 'merchant') return `${tenantName}.merchant.curfox.com`
      return `${tenantName}.office.curfox.com`
    },
  },
  methods: {
    async openModal(tenant, user, userType) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.tenant = tenant
      this.user = user
      this.userType = userType
      await this.generatePassword()
    },
    async generatePassword() {
      this.loading = true
      try {
        const payload = {
          user_id: this.user.id,
          user_type: this.userType,
        }
        const res = await TenantUserRepository.generateTemporaryPassword(this.tenant.id, payload)
        if (res.status === 200) {
          this.generatedPassword = res.data.data
        } else {
          this.showErrorMessage('Failed to impersonate user')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
