<template>
  <div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="User Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="filters['email']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
        >
          <JsonExcel
            class="btn p-0"
            :data="rows"
            :fields="json_fields"
            type="csv"
            :name="`${userType =='merchant' ? 'MerchantUsers':'Staff'} - Page ${page}.xls`"
          >
            <b-button
              v-b-tooltip.hover.v-primary="'Download Excel - Page'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              size="sm"
            >
              <mdicon
                name="Download"
                size="13"
              />
            </b-button>
          </JsonExcel>
          <JsonExcel
            class="btn p-0"
            :fetch="getTenantUserListNoPagination"
            :before-generate="__showJsonDownloadAlert"
            type="csv"
            :name="`${userType =='merchant' ? 'MerchantUsers':'Staff'}.xls`"
          >
            <b-button
              v-b-tooltip.hover.v-primary="'Download Excel - All'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              size="sm"
            >
              <mdicon
                name="DownloadMultiple"
                size="13"
              />
            </b-button>
          </JsonExcel>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary="'Refresh Table'"
            variant="outline-primary"
            size="sm"
            @click="onClickRefresh()"
          >
            <feather-icon
              icon="RefreshCwIcon"
            />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <b-overlay
          id="overlay-background"
          :show="loading"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            show-empty
          >
            <template #cell(client_name)="data">
              <div
                class="d-flex align-items-center"
              >
                <b-avatar
                  v-if="data.item.logo === null"
                  :text="avatarText(data.value)"
                  size="32"
                  variant="light-primary"
                />
                <b-avatar
                  v-else
                  :src="`${data.item.logo}`"
                  size="32"
                />
                <div>
                  <div
                    class="font-weight-bolder ml-1 text-primary"
                  >
                    {{ data.value }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(status_key)="data">
              <package-status-badge :status-key="data.value" />
            </template>
            <template #cell(action)="data">
              {{ data.value }}
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="onClickImpersonate(data.item)"
                >
                  <feather-icon icon="TerminalIcon" />
                  Impersonate
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row
            cols="12"
          >
            <b-col
              class="d-flex align-items-center justify-content-sm-start"
              md="3"
            >
              <label class="width-7 mr-1">Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block width-100"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-sm-end"
              md="9"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <impersonate-modal ref="impersonateModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BCol,
  BRow,
  BPagination,
  BTable,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BButton,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import JsonExcel from 'vue-json-excel'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import ImpersonateModal from './ImpersonateModal.vue'

const TenantUserRepository = RepositoryFactory.get('tenantUser')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BCol,
    BRow,
    BPagination,
    BTable,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BButton,
    BFormInput,
    JsonExcel,
    vSelect,
    ImpersonateModal,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  props: {
    userType: {
      type: String,
      required: true,
    },
    selectedTenant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filters: {},
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'first_name',
          label: 'First Name',
          sortable: true,
        },
        {
          key: 'last_name',
          label: 'Last Name',
          sortable: false,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          key: 'merchant.name',
          label: 'Merchant',
          sortable: false,
        },
        {
          key: 'phone',
          label: 'Phone',
          sortable: false,
        },
        {
          key: 'is_active',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  watch: {
    async perPage() {
      await this.getTenantUserList()
    },
    async page() {
      await this.getTenantUserList()
    },
  },
  mounted() {
    if (this.userType !== 'merchant') this.fields = this.fields.filter(field => field.key !== 'merchant')
  },
  methods: {
    async getTenantUserList() {
      this.loading = true
      try {
        const { data } = (await TenantUserRepository.getTenantUsers(this.selectedTenant.id, this.userType, this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getTenantUserListNoPagination() {
      try {
        const { data } = (await TenantUserRepository.getTenantUsersNoPagination(this.selectedTenant.id, this.userType, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickImpersonate(user) {
      this.$refs.impersonateModal.openModal(this.selectedTenant, user, this.userType)
    },
    filterQueryUpdate() {
      this.getTenantUserList()
    },
    handleChangePage(page) {
      this.page = page
      this.getTenantUserList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getTenantUserList()
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
