<template>
  <div>
    <b-card title="Business">
      <b-row>
        <b-col
          cols="12"
          lg="6"
          class="my-1 align-items-start"
        >
          <v-select
            v-model="selectedTenant"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select The Business Name"
            :options="tenants"
          />
        </b-col>
        <b-col
          md="3"
          sm="2"
          class="my-1"
        />
        <b-col
          md="3"
          sm="2"
          class="my-1"
        />
      </b-row>
    </b-card>
    <b-card
      v-if="selectedTenant"
      no-body
      class="w-100"
    >
      <b-tabs
        :key="selectedTenant"
        justified
        pills
      >
        <b-tab
          active
        >
          <template #title>
            <b-card-text class="mx-1 font-weight-bolder">
              Staff
            </b-card-text>
          </template>
          <tenant-user-table
            user-type="staff"
            :selected-tenant="selectedTenant"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <b-card-text class="mx-1 font-weight-bolder">
              Merchant Users
            </b-card-text>
          </template>
          <tenant-user-table
            user-type="merchant"
            :selected-tenant="selectedTenant"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import TenantUserTable from '@/components/TenantUsers/TenantUserTable.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ClientRepository = RepositoryFactory.get('client')

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BTabs,
    BTab,
    BCardText,
    vSelect,
    TenantUserTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      selectedTenant: null,
      tenants: [],
    }
  },
  mounted() {
    this.getClientListForDropdown()
  },
  methods: {
    async getClientListForDropdown() {
      this.loading = true
      try {
        const { data } = await ClientRepository.getClientListForDropdown()
        this.tenants = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
